<template>
	<div class="full-width"
		:style="{
			'height': height + 'px',
			'background-color': color,
			'border-radius': borderRadius + 'px'
		}"
	/>
</template>

<script>
export default {
	name: "Divider",
	props: {
		height: {
			type: String,
			default: "2"
		},
		color: {
			type: String,
			default: "whitesmoke"
		},
		borderRadius: {
			type: String,
			default: "20"
		}
	},
}
</script>

<style scoped>

</style>
